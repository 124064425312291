import { Jodit } from "jodit";

interface MenuOption {
	value: string;
	title: string;
	group?: string;
}

interface MenuGroup {
	title: string;
	menu: MenuOption[];
}

interface SlugValueConfig {
	tags: MenuGroup[];
}

const SlugValue = (editor: Jodit, config: SlugValueConfig) => {
	let popup: HTMLDivElement | null = null;
	let filteredOptions: MenuOption[] = [];
	let selectedIndex = -1;
	let query = "";

	const handleOpen = (position: DOMRect, options: MenuOption[]) => {
		if (!popup) {
			popup = document.createElement("div");
			popup.style.position = "absolute";
			popup.style.zIndex = "1000";
			popup.style.maxHeight = "max(50vh, 350px)";
			popup.style.overflowY = "auto";
			popup.className = "dropdown-menu show shadow-sm";
			document.body.appendChild(popup);

			popup.addEventListener("mousedown", (e) => e.preventDefault());
		}

		popup.style.top = `${position.bottom + window.scrollY}px`;
		popup.style.left = `${position.left + window.scrollX}px`;

		popup.innerHTML = "";
		let group = "";
		options.forEach((option, index) => {
			if (option.group && option.group !== group) {
				group = option.group;
				const item = document.createElement("div");
				item.textContent = option.group;
				item.className = "dropdown-header";
				item.style.fontSize = "14px";
				item.style.fontWeight = "600";
				item.style.color = "black";
				item.style.cursor = "pointer";
				popup?.appendChild(item);
			}

			const item = document.createElement("div");
			item.textContent = option.title;
			item.className = index === selectedIndex ? "dropdown-item active" : "dropdown-item";
			item.style.fontSize = "14px";
			item.style.cursor = "pointer";

			item.addEventListener("mousedown", () => {
				handleInsert(option);
				handleClose();
			});

			popup?.appendChild(item);
		});
	};

	const handleClose = () => {
		if (popup) {
			popup.remove();
			popup = null;
			selectedIndex = -1;
			query = "";
		}
	};

	// const handleMenu = (command: string, second: string, third: string) => {
	// 	editor.selection.insertHTML(`{{${third}}}`);
	// };

	const handleText = () => {
		const selection = editor.selection.sel?.getRangeAt(0);
		if (!selection) return "";

		const textBeforeCursor = selection.startContainer.textContent?.slice(0, selection.startOffset);

		const matches = textBeforeCursor?.match(/{{[^{}]*$/g);

		return matches ? matches[matches.length - 1] : "";
	};

	const handleInsert = (option: MenuOption) => {
		if (editor.selection) {
			const text = handleText();

			const textRange = document.createRange();
			textRange.setStart(editor.selection.range.startContainer!, editor.selection.range.endOffset - text.length);
			textRange.setEnd(editor.selection.range.startContainer!, editor.selection.range.endOffset);
			textRange.deleteContents();

			editor.selection.insertHTML(`{{${option.value}}}`);
		}
	};

	editor.events.on("keydown", (e: KeyboardEvent) => {
		if (popup) {
			const selection = window.getSelection();
			const range = selection!.getRangeAt(0); // Obtém o primeiro range
			const rect = range!.getBoundingClientRect(); // Obtém a posição do cursor
			if (e.key === "ArrowDown") {
				e.preventDefault();
				selectedIndex = (selectedIndex + 1) % filteredOptions.length;
				handleOpen(rect!, filteredOptions);
			} else if (e.key === "ArrowUp") {
				e.preventDefault();
				selectedIndex = (selectedIndex - 1 + filteredOptions.length) % filteredOptions.length;
				handleOpen(rect!, filteredOptions);
			} else if (e.key === "ArrowLeft") {
				e.preventDefault();
			} else if (e.key === "ArrowRight") {
				e.preventDefault();
			} else if (e.key === "Enter") {
				e.preventDefault();
				if (selectedIndex >= 0 && filteredOptions[selectedIndex]) {
					handleInsert(filteredOptions[selectedIndex]);
				}
				handleClose();
			} else if (e.key === "Escape") {
				handleClose();
			}
		}
	});

	editor.events.on("input", () => {
		const cursorPosition = editor.selection.current();
		if (!cursorPosition) return;

		const text = handleText();
		const match = text.match(/{{([^{}]*)$/);

		if (match) {
			query = match[1].trim();

			const selection = window.getSelection();
			if (selection && selection.rangeCount > 0) {
				const range = selection.getRangeAt(0); // Obtém o primeiro range
				const rect = range.getBoundingClientRect(); // Obtém a posição do cursor

				filteredOptions = config.tags.flatMap((group) =>
					group.menu
						.filter((option) => option.title.toLowerCase().includes(query.toLowerCase()))
						.map((item) => {
							return { ...item, group: group.title };
						})
				);

				if (filteredOptions.length > 0) {
					handleOpen(rect, filteredOptions);
				} else {
					handleClose();
				}
			}
		} else {
			handleClose();
		}
	});

	editor.events.on("mousedown", (e) => {
		if (popup && !popup.contains(e.target as Node)) {
			handleClose();
		}
	});

	// editor.registerButton({ name: "SlugValue", group: "insert" });

	// editor.registerCommand("SlugValue", handleMenu);

	// Config.prototype.controls.SlugValue = {
	// 	tooltip: "ReHabi",
	// 	iconURL: process.env.PUBLIC_URL + "/icone.webp",
	// 	command: "SlugValue",
	// 	list: config.tags.reduce((result: any, group) => {
	// 		group.menu.forEach(({ value, title }) => {
	// 			result[value] = title;
	// 		});
	// 		return result;
	// 	}, {}),
	// };
};

export default SlugValue;
