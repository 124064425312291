import ApiService from "./ApiService";
import { UnitGetUnitsResponse, UnitGetResponse, UnitPostRequest, UnitPostResponse, UnitPutRequest, UnitPutResponse, UnitDeleteResponse } from "../entities/UnitEntity";

export default class UnitService extends ApiService {
	public async getUnits(clientId: number) {
		return await this.request.get<UnitGetUnitsResponse>(`/api/v1/Units`, { params: { clientId: clientId } });
	}
	public async get(id: number) {
		return await this.request.get<UnitGetResponse>(`/api/v1/Unit/${id}`);
	}
	public async post(data: UnitPostRequest) {
		return await this.request.post<UnitPostResponse>(`/api/v1/Unit`, data);
	}
	public async put(id: number, data: UnitPutRequest) {
		return await this.request.put<UnitPutResponse>(`/api/v1/Unit/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<UnitDeleteResponse>(`/api/v1/Unit/${id}`);
	}
}
