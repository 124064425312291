import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios, { AxiosError, AxiosResponse } from "axios";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiFileDocumentOutline, mdiTextBoxOutline, mdiTrashCanOutline } from "@mdi/js";

import { Jodit } from "jodit";
import { justify } from "jodit/esm/plugins/justify/justify";
import { indent } from "jodit/esm/plugins/indent/indent";
import { hr } from "jodit/esm/plugins/hr/hr";
import { source } from "jodit/esm/plugins/source/source";
import { lineHeight } from "jodit/esm/plugins/line-height/line-height";
import SlugCondition from "../../services/JoditSlugCondition";
import SlugValue from "../../services/JoditSlugValue";
import "jodit/es5/jodit.min.css";

import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { ModuleEntity, ModulePermissionEntity } from "../../config/defines";
import { modulePermissionCheck } from "../../config/utils";
import { TemplateEntity, TemplateGetSlugsResponse, TemplatePostRequest, TemplatePutRequest, TemplatePutResponse } from "../../entities/TemplateEntity";
import TemplateService from "../../services/TemplateService";

import Layout from "../../components/Layout";

const toastTitle = "Laudo";

export default function AvaliacaoLaudos() {
	const templateService = new TemplateService();

	const navigate = useNavigate();
	const { user } = useAuth();
	const { id, questionnaireId } = useParams();
	const { handleToast } = useToast(toastTitle);
	const queryClient = useQueryClient();

	const [joditEditor, setJoditEditor] = useState<Jodit>();

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);

	const [removeModal, setRemoveModal] = useState(false);
	const [removeLoadingModal, setRemoveLoadingModal] = useState(false);
	const [ajudaModal, setAjudaModal] = useState(false);

	const { data, isLoading, isFetching, isRefetching } = useQuery<TemplateEntity>(["template", id], () => fetchData(Number(id)), { enabled: !!id && !formStatus });
	const querySlugs = useQuery<TemplateGetSlugsResponse>(["avaliacao", questionnaireId, "slugs"], () => fetchDataSlugs(Number(questionnaireId)), { enabled: !!questionnaireId && !formStatus });

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationPut = useMutation(mutatePut, { onSuccess: mutateSuccess, onError: mutateError });

	const { register, handleSubmit, reset } = useForm({ defaultValues: data });

	useEffect(() => {
		if (querySlugs.isFetched && querySlugs.data) {
			let tags = [];
			for (let i in querySlugs.data) {
				let slug = querySlugs.data[i];
				let grupoIndex = tags.findIndex((item) => {
					return item.title === slug.groupName;
				});
				if (grupoIndex >= 0) {
					tags[grupoIndex]["menu"].push({
						value: slug.slug,
						title: slug.name,
					});
				} else {
					tags.push({
						title: slug.groupName,
						menu: [{ value: slug.slug, title: slug.name }],
					});
				}
			}

			Jodit.plugins.add("SlugValue", function (jodit: any) {
				SlugValue(jodit, { tags: tags });
			});
			Jodit.plugins.add("SlugCondition", function (jodit: any) {
				SlugCondition(jodit, { tags: tags });
			});
			Jodit.plugins.add("justify", function (jodit: any) {
				justify(jodit);
			});
			Jodit.plugins.add("indent", function (jodit: any) {
				indent(jodit);
			});
			Jodit.plugins.add("hr", function (jodit: any) {
				hr(jodit);
			});
			Jodit.plugins.add("lineHeight", function (jodit: any) {
				new lineHeight(jodit);
			});
			Jodit.plugins.add("source", function (jodit: any) {
				new source(jodit);
			});

			const joditEditor = Jodit.make("#joditEditor", {
				language: "pt_br",
				minHeight: 400,
				toolbarButtonSize: "large",
				uploader: {
					insertImageAsBase64URI: true, // Converte imagens para Base64
				},
				createAttributes: {
					table: { style: "border: 1px solid black;" },
					tr: { style: " border: 1px solid black;" },
					td: { style: " border: 1px solid black;" },
				},
			});

			setJoditEditor(joditEditor);
		}
	}, [querySlugs.isFetched]);

	useEffect(() => {
		if (joditEditor) {
			joditEditor.setDisabled(!formStatus);
			joditEditor.setReadOnly(!formStatus);
		}
	}, [formStatus, joditEditor]);

	useEffect(() => {
		if (joditEditor && data) {
			joditEditor.setEditorValue(data.content);
		}
	}, [joditEditor, data]);

	async function fetchData(id: number) {
		let resp = await templateService.get(id);
		let data = resp.data;
		reset(data);
		return data;
	}

	async function fetchDataSlugs(id: number) {
		let resp = await templateService.getSlugs(id);
		return resp.data;
	}

	async function mutatePost(data: TemplatePostRequest) {
		setFormSaving(true);
		return await templateService.post(data);
	}

	function mutatePostSuccess(resp: AxiosResponse<TemplateEntity, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		navigate(`/avaliacaoLaudos/${questionnaireId}/${resp.data.id}`);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutatePut(data: TemplatePutRequest) {
		setFormSaving(true);
		return await templateService.put(Number(id), data);
	}

	function mutateSuccess(resp: AxiosResponse<TemplatePutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(false);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	async function handleRemove() {
		setRemoveLoadingModal(true);
		try {
			let resp = await templateService.delete(Number(id));
			if (resp.status === 200) {
				queryClient.refetchQueries(["templates", questionnaireId]);
				queryClient.invalidateQueries(["template", id]);
				handleToast(toastTitle, "Removido com sucesso!", 5000);
				navigate(`/avaliacaoConfiguracoes/${questionnaireId}`);
			} else {
				handleToast(toastTitle, resp.statusText, 5000, "danger");
			}
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				const axiosError = error as AxiosError<any>;
				handleToast(toastTitle, axiosError.response?.data.message, 5000, "danger");
			}
		}
		setRemoveModal(false);
		setRemoveLoadingModal(false);
	}

	return (
		<Layout>
			<Form
				onSubmit={handleSubmit((values) => {
					if (id) {
						mutationPut.mutate({
							description: values.description,
							content: joditEditor!.getEditorValue(),
						});
					} else {
						mutationPost.mutate({
							questionnaireId: Number(questionnaireId),
							description: values.description,
							content: joditEditor!.getEditorValue(),
						});
					}
				})}
			>
				<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
					<Link to={`/avaliacaoConfiguracoes/${questionnaireId}`} className="d-flex text-decoration-none">
						<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiFileDocumentOutline} size={1} className="me-1" /> Formulário de Laudo
					</Link>
					{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
					<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
						{modulePermissionCheck(user!, ModuleEntity.configuracoesAvaliacoes, ModulePermissionEntity.editar) && (
							<>
								<Button
									variant="light"
									className="rounded-3 shadow-sm"
									onClick={() => {
										setAjudaModal(true);
									}}
								>
									<Icon path={mdiTextBoxOutline} size={1} /> Ajuda
								</Button>
								{!formStatus && (
									<>
										<Button
											variant="light"
											className="rounded-3 shadow-sm"
											onClick={() => {
												setFormStatus(true);
											}}
										>
											Editar Informações
										</Button>
										<Button
											variant="light"
											className="rounded-3 shadow-sm"
											onClick={() => {
												setRemoveModal(true);
											}}
										>
											<Icon path={mdiTrashCanOutline} size={1} className="text-danger" /> Remover
										</Button>
									</>
								)}
								{formStatus && (
									<>
										<Button
											variant="light"
											className="rounded-3 shadow-sm"
											type="button"
											onClick={() => {
												if (id) {
													setFormStatus(false);
												} else {
													navigate("/dominios");
												}
											}}
											disabled={formSaving}
										>
											Cancelar
										</Button>
										<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
											{formSaving ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Salvando
												</>
											) : (
												"Salvar Informações"
											)}
										</Button>
									</>
								)}
							</>
						)}
					</div>
				</h5>

				<Card className="mt-3 mb-4">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Laudo</Card.Header>
					<Card.Body>
						<Form.Group className="mb-3" controlId="description">
							<Form.Label>Descrição</Form.Label>
							<Form.Control type="text" placeholder="Informe aqui" {...register("description", { required: true })} disabled={!formStatus} />
						</Form.Group>

						<Form.Group className="mb-3" controlId="editor">
							<Form.Label>Conteúdo</Form.Label>
							<div id="joditEditor"></div>
						</Form.Group>
					</Card.Body>
				</Card>

				{modulePermissionCheck(user!, ModuleEntity.configuracoesDominios, ModulePermissionEntity.editar) && (
					<div className="d-flex gap-2 mb-4">
						<Button
							variant="light"
							className="rounded-3 shadow-sm"
							onClick={() => {
								setAjudaModal(true);
							}}
						>
							<Icon path={mdiTextBoxOutline} size={1} /> Ajuda
						</Button>
						{!formStatus && (
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						)}
						{formStatus && (
							<>
								<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								<Button
									variant="light"
									className="rounded-3 shadow-sm"
									type="button"
									onClick={() => {
										if (id) {
											setFormStatus(false);
										} else {
											navigate("/configuracoesDominios");
										}
									}}
									disabled={formSaving}
								>
									Cancelar
								</Button>
							</>
						)}
					</div>
				)}
			</Form>

			<Modal
				show={removeModal}
				onHide={() => {
					setRemoveModal(false);
				}}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Remover Laudo</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">{!removeLoadingModal ? "Deseja realmente remover este laudo?" : <Spinner />}</Modal.Body>
				{!removeLoadingModal && (
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={() => {
								setRemoveModal(false);
							}}
						>
							Cancelar
						</Button>
						<Button variant="danger" onClick={handleRemove}>
							Remover
						</Button>
					</Modal.Footer>
				)}
			</Modal>

			<Modal
				show={ajudaModal}
				onHide={() => {
					setAjudaModal(false);
				}}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>Ajuda</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<strong>📘 Guia de Uso do Editor</strong>
					<p>Você pode inserir valores dinâmicos e criar regras condicionais para personalizar o conteúdo com base nas respostas da avaliação.</p>

					<strong>🔹 Valores Dinâmicos</strong>
					<p>
						São inseridos no formato <code className="bg-light p-1 rounded">{"{{número}}"}</code>. Eles representam respostas que serão substituídas automaticamente pelo sistema.
					</p>
					<div className="bg-light p-3 rounded my-3 font-monospace small">Resposta: {"{{1}}"}</div>

					<strong>🔹 Condições</strong>
					<p>
						Permitem exibir textos diferentes dependendo dos valores da avaliação. Elas seguem o formato <code className="bg-light p-1 rounded">{"[[código]]texto[[código]]"}</code>
					</p>

					<p>Exemplo de condição baseada na existência da resposta:</p>
					<div className="bg-light p-3 rounded my-3 font-monospace small">
						{"[[2]]"} Resposta 2: {"{{2}}"} {"[[2]]"}
					</div>

					<p>Exemplo de condição baseada em valor numérico:</p>
					<div className="bg-light p-3 rounded my-3 font-monospace small">
						{"[[3>100]]"} Resposta maior que 100 {"[[3]]"}
					</div>
					<p className="text-muted small">
						Se a resposta da questão <code className="bg-light p-1 rounded">3</code> for maior que <code className="bg-light p-1 rounded">100</code>, o texto será exibido.
					</p>

					<p>Exemplo para uma opção exata:</p>
					<div className="bg-light p-3 rounded my-3 font-monospace small">
						{"[[4;1]]"} Opção 1 selecionada {"[[4]]"}
					</div>
					<p className="text-muted small">
						Se a resposta da questão <code className="bg-light p-1 rounded">4</code> for <code className="bg-light p-1 rounded">1</code>, o texto será exibido.
					</p>

					<p>Exemplo com múltiplos valores:</p>
					<div className="bg-light p-3 rounded my-3 font-monospace small">
						{"[[5;1,10]]"} Primeira Seleção Opção 1 e Segunda Seleção Opção 10{"[[5]]"}
					</div>
					<p className="text-muted small">
						Se a resposta da questão <code className="bg-light p-1 rounded">5</code> for <code className="bg-light p-1 rounded">1</code> e <code className="bg-light p-1 rounded">10</code>,
						o texto será exibido.
					</p>

					<strong>🔹 Exemplo Completo</strong>
					<div className="bg-light p-3 rounded my-3 font-monospace small">
						Resposta: {"{{1}}"}
						<br />
						{"[[2]]"} Resposta: {"{{2}}"} {"[[2]]"}
						<br />
						{"[[3>100]]"} Resposta maior que 100 {"[[3]]"}
						<br />
						{"[[3<100]]"} Resposta menor que 100 {"[[3]]"}
						<br />
						{"[[4;1]]"} Opção 1 selecionada {"[[4]]"}
						<br />
						{"[[4;2]]"} Opção 2 selecionada {"[[4]]"}
						<br />
						{"[[5;1,10]]"} Primeira Seleção Opção 1 e Segunda Seleção Opção 10{"[[5]]"}
						<br />
					</div>

					<strong>🔹 Dicas Importantes</strong>
					<p>
						✅ Sempre feche os blocos condicionais com <code className="bg-gray-200 p-1 rounded">{"[[código]]"}</code>.<br />✅ Use <code className="bg-gray-200 p-1 rounded">;</code> para
						valores exatos e <code className="bg-gray-200 p-1 rounded">{">"}</code> ou <code className="bg-gray-200 p-1 rounded">{"<"}</code> para comparações numéricas.
						<br />✅ Os placeholders <code className="bg-gray-200 p-1 rounded">{"{{número}}"}</code> podem ser usados dentro de condicionais{" "}
						<code className="bg-gray-200 p-1 rounded">{"[[código]]"}</code>.
					</p>
				</Modal.Body>
			</Modal>
		</Layout>
	);
}
