import ApiService from "./ApiService";
import {
	TemplateDeleteResponse,
	TemplateGetResponse,
	TemplateGetSlugsResponse,
	TemplateGetTemplatesResponse,
	TemplatePostRequest,
	TemplatePostResponse,
	TemplatePutRequest,
	TemplatePutResponse,
} from "../entities/TemplateEntity";

export default class TemplateService extends ApiService {
	public async getTemplates(questionnaireId: number) {
		return await this.request.get<TemplateGetTemplatesResponse>(`/api/v1/Templates`, { params: { questionnaireId: questionnaireId } });
	}
	public async get(id: number) {
		return await this.request.get<TemplateGetResponse>(`/api/v1/Template/${id}`);
	}
	public async getSlugs(questionnaireId: number) {
		return await this.request.get<TemplateGetSlugsResponse>(`/api/v1/TemplateSlugs`, { params: { questionnaireId: questionnaireId } });
	}
	public async post(data: TemplatePostRequest) {
		return await this.request.post<TemplatePostResponse>(`/api/v1/Template`, data);
	}
	public async put(id: number, data: TemplatePutRequest) {
		return await this.request.put<TemplatePutResponse>(`/api/v1/Template/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<TemplateDeleteResponse>(`/api/v1/Template/${id}`);
	}
}
